import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { FC } from "react";
import styles from "@/styles/Footer.module.scss";
import { useIsSM } from "@/utils/hooks/useWindowSizes";
import NewsletterForm from "./NewsLetterForm";

const KeepInTouch: FC = () => {
    const isSM = useIsSM();
    return (
        <div
            className={`${
                isSM ? styles.footBgColorBgMobile : styles.footBgColorBgDesktop
            } bg-image d-flex align-items-center justify-content-center px-0 vh-100`}
        >
            <div className="mask d-flex justify-content-center align-items-center">
                <MDBContainer className="text-center py-5" fluid>
                    <MDBRow className="d-flex justify-content-center mb-5 py-5 my-5">
                        <MDBCol sm="8" md="8" lg="6" xl="8" className="pt-5">
                            <h2
                                className={`${styles.kit_title} text-uppercase fw-bold mt-5`}
                            >
                                Keep in touch
                            </h2>
                            <p
                                className={`${styles.text_content} fw-normal py-2`}
                            >
                                Stay updated on our trips, promotions and
                                stories.
                            </p>
                            <MDBRow
                                className={` ${styles.max_padding} d-flex justify-content-center pb-5`}
                            >
                                <NewsletterForm isSmall />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
    );
};

export default KeepInTouch;
