import { useQuery } from "@tanstack/react-query";
import { getVideoReviewsData } from "../index";

const useVideoReviewData = () => {
    return useQuery({
        queryKey: ["videoReview"],
        queryFn: () => getVideoReviewsData(),
        refetchOnWindowFocus: false,
    });
};

export default useVideoReviewData;
