import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { FC, ReactNode, useState } from "react";
import SwiperClass from "swiper";
import styles from "../../styles/common/carousel/Carousel.module.scss";

import {
    ISectionTitle,
    SectionsTitleText,
    TBreackpointsCarousel,
} from "../../models/common/common";

import ButtonBack from "./carousel/ButtonBack";
import ButtonNext from "./carousel/ButtonNext";
import SectionTitle from "./SectionTitle";
import Carousel from "./carousel/Carousel";
import { useIsMD } from "../../utils/hooks/useWindowSizes";

const SectionCarouselLayout: FC<{
    children: ReactNode[];
    visibleSlides?: number;
    sectionTitle: SectionsTitleText[];
    sectionSubTitle: SectionsTitleText[];
    isCarouselContained: boolean;
    tiktok?: boolean;
    classNameCarousel?: string;
    breakpointsCarousel?: TBreackpointsCarousel;
}> = ({
    children,
    sectionTitle,
    sectionSubTitle,
    isCarouselContained,
    classNameCarousel = "",
    visibleSlides = 3,
    breakpointsCarousel,
    tiktok = false,
}) => {
    const [swiperClass, setSwiperClass] = useState<SwiperClass | null>(null);

    const title: ISectionTitle = {
        title: sectionTitle,
        subTitle: sectionSubTitle,
        alignment: "justify-content-start text-start",
        classNameContainer: "ms-auto ms-md-0 mx-0 px-0 py-3",
        titleColumnSize: 12,
        spaceBetween: 2,
    };

    const isMD = useIsMD();

    return (
        <>
            <MDBRow className="d-flex justify-content-center">
                <MDBCol
                    xs={12}
                    md={10}
                    className={`d-flex ${
                        isCarouselContained ? "pe-5 ps-3" : "px-3"
                    }`}
                >
                    <MDBCol xs={12} md={10}>
                        <SectionTitle sectionTitle={title} />
                    </MDBCol>
                    <MDBCol className="d-none d-md-flex justify-content-end">
                        <MDBRow className="justify-content-end py-3">
                            <ButtonBack swiperClass={swiperClass} />
                            <ButtonNext
                                className="ms-2"
                                swiperClass={swiperClass}
                            />
                        </MDBRow>
                    </MDBCol>
                </MDBCol>
            </MDBRow>
            <MDBRow
                className={`d-flex ${
                    isCarouselContained && !isMD
                        ? "justify-content-center"
                        : "justify-content-end"
                }`}
            >
                <MDBCol
                    size={`${isCarouselContained && !isMD ? 10 : 11}`}
                    className={`${!tiktok && styles.mobileHeight}  ${
                        !tiktok && styles.desktopHeight
                    } py-2 mx-2`}
                >
                    <Carousel
                        classNameCarousel={classNameCarousel}
                        visibleSlides={visibleSlides}
                        setSwiperClass={setSwiperClass}
                        breakpoints={breakpointsCarousel}
                        centerInsufficientSlides={false}
                    >
                        {children}
                    </Carousel>
                </MDBCol>
            </MDBRow>
            <MDBRow className="d-flex d-md-none justify-content-start px-5 py-4">
                <ButtonBack swiperClass={swiperClass} />
                <ButtonNext className="ms-2" swiperClass={swiperClass} />
            </MDBRow>
        </>
    );
};

export default SectionCarouselLayout;
