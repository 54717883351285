import { MDBBtn } from "mdb-react-ui-kit";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { VideoreviewsEntity } from "@/models/home";
import useOnScreen from "@/utils/hooks/useOnScreen";
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager from "react-gtm-module";
import {
    videoComplete,
    videoProgressReview,
    videoStartReview,
} from "@/utils/analytics/tagmanager";
import styles from "../../../styles/home/Home.CardTikTok.module.scss";
import mediaControlSvg from "../../../utils/svg-icon/mediaControl";

type sendEventProp = {
    percent10: boolean;
    percent25: boolean;
    percent50: boolean;
    percent75: boolean;
};

const CardTikTok: FC<{
    content: VideoreviewsEntity;
}> = ({ content }) => {
    const { Play } = mediaControlSvg;
    const [showVideoFrame, setShowVideoFrame] = useState(false);
    const [previewImageError, setPreviewImageError] = useState(false);
    const videoEl = useRef<HTMLVideoElement>(null);
    const divEl = useRef<HTMLDivElement>(null);
    const [sendEvent, setSendEvent] = useState<sendEventProp>({
        percent10: false,
        percent25: false,
        percent50: false,
        percent75: false,
    });

    useEffect(() => {
        const onStartVideo = () => {
            TagManager.dataLayer(videoStartReview(content.video, content.name));
        };
        if (showVideoFrame) onStartVideo();
    }, [content.name, content.video, showVideoFrame]);

    const onEndAction = () => {
        TagManager.dataLayer(videoComplete(content.video, content.name));
    };

    const onPauseAction = () => {
        const durata = videoEl.current?.duration;
        const currentTime = videoEl.current?.currentTime;
        const percentuale = (100 / durata!) * currentTime!;

        const percentageBody = (percent: number) => {
            return TagManager.dataLayer(
                videoProgressReview(content.video, content.name, percent)
            );
        };

        // if (percentuale >= 10 && sendEvent.percent10 === false) {
        //     percentageBody(percentuale);
        //     setSendEvent({ ...sendEvent, percent10: true });
        // }
        // if (percentuale >= 25 && sendEvent.percent25 === false) {
        //     percentageBody(percentuale);
        //     setSendEvent({ ...sendEvent, percent25: true });
        // }
        if (percentuale >= 50 && sendEvent.percent50 === false) {
            percentageBody(percentuale);
            setSendEvent({ ...sendEvent, percent50: true });
        }

        // if (percentuale >= 75 && sendEvent.percent75 === false) {
        //     percentageBody(percentuale);
        //     setSendEvent({ ...sendEvent, percent75: true });
        // }
    };

    const isOnScreen = useOnScreen(divEl as RefObject<HTMLElement>);
    useEffect(() => {
        if (!isOnScreen) {
            videoEl.current?.pause();
        }
    }, [isOnScreen]);

    return (
        <div
            className={`${styles.container} bg-image d-flex align-items-center justify-content-center px-0 w-100`}
            ref={divEl}
        >
            {showVideoFrame && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                    className="w-100 h-100"
                    controls
                    autoPlay
                    title="review video"
                    ref={videoEl}
                    onEnded={onEndAction}
                    onPause={onPauseAction}
                >
                    <source src={content.video} />
                </video>
            )}
            {!showVideoFrame && (
                <>
                    <div className={`${styles.imageCover} position-relative`}>
                        <Image
                            className="object-cover h-100"
                            src={
                                previewImageError
                                    ? "/images/tours/across-italy-reduced.jpg"
                                    : content.previewImage
                            }
                            alt="detail-image"
                            fill
                            onError={() => {
                                setPreviewImageError(true);
                            }}
                        />
                    </div>
                    <div className={`${styles.mask_overlay} mask`}>
                        <MDBBtn
                            className={`${styles.play_button} p-4 shadow-0`}
                            onClick={() => setShowVideoFrame(!showVideoFrame)}
                        >
                            <Play />
                        </MDBBtn>
                    </div>
                </>
            )}
        </div>
    );
};

export default CardTikTok;
