import { RefObject, useEffect, useMemo, useState } from "react";

const useOnScreen = (ref: RefObject<HTMLElement> | null) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            typeof window !== "undefined"
                ? new IntersectionObserver(([entry]) =>
                      setIntersecting(entry.isIntersecting)
                  )
                : null,
        [ref]
    );

    useEffect(() => {
        if (ref) {
            observer?.observe(ref.current!);
        }
        return () => observer?.disconnect();
    }, []);

    return isIntersecting;
};

export default useOnScreen;
